import { createWebHistory, createRouter } from 'vue-router'
const Home = () => import('@/pages/Home')
const ContactUs = () => import('@/pages/ContactUs')
const Training = () => import('@/pages/Training')
const TrainingPage = () => import('@/pages/TrainingPage')
const AboutUs = () => import('@/pages/AboutUs')
// Project Management

const PMImplementation = () => import('@/pages/ProjectManagement/PMImplementation')


const routes = [
    {
        name: 'Home',
        component: Home,
        path: '/'
    },

    {
        name: 'ContactUs',
        component: ContactUs,
        path: '/contact-us'
    },

    {
        name: 'Training',
        component: Training,
        path: '/training/:slug'
    },

    {
        name: 'AboutUs',
        component: AboutUs,
        path: '/about-us'
    },

    {
        name: 'courseTraining',
        component: TrainingPage,
        path: '/course-training'
    },
    
    


    {
        name: 'PMImplementation',
        component: PMImplementation,
        path: '/course/:slug'
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
      },
});

export default router;