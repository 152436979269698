<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/"
        ><img
          class="bes_logo"
          :src="'../images/assets/BES_blue.png'"
          alt="British Lyceum Executive School"
      /></router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbarSupportedContent" class="navbar-collapse collapse">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link to="/" class="nav-link">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/course-training"
              >Trainings</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/about-us" class="nav-link">About Us</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/contact-us" class="nav-link"
              >Contact Us</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "WebsiteNavbar",
};
</script>

<style scoped>
nav {
  padding-left: 4vw;
  padding-right: 4vw;
}

.bes_logo {
  width: 210px;
}

.navbar .container-fluid {
  background-color: #fff;
  height: 12rem !important;
}

.navbar .container-fluid .navbar-nav .nav-item a {
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  color: #000;
  font-weight: 400;
  margin-right: 2vw;
  white-space: nowrap;
}

.navbar .container-fluid .navbar-nav .nav-item a:hover {
  color: #29577f;
  font-weight: 600;
}

.navbar .container-fluid .navbar-nav .nav-item a.router-link-active {
  color: #29577f;
  font-weight: 600;
}

.navbar .container-fluid .navbar-collapse {
  max-width: min-content;
}

.navbar-toggler-icon {
  outline: none !important;
}

@media screen and (max-width: 1200px) {
  nav {
    padding-right: 1vw;
    padding-left: 1vw;
  }

  .navbar .container-fluid .navbar-nav .nav-item a {
    font-size: 1.3rem;
    margin-right: 0.5vw;
  }
}

@media screen and (max-width: 991px) {
  .navbar .container-fluid .navbar-collapse {
    max-width: none;
    background-color: #fff;
    padding-left: 2vw;
    transform: translateY(-94px);
  }

  .navbar-collapse {
    /* border: 1px solid !important; */
    background: transparent !important;
  }

  .navbar-nav {
    /* border: 1px solid !important; */
    width: fit-content !important;
    float: right !important;
    padding-right: 1rem !important;
    padding-left: 2rem !important;
    align-items: self-end !important;
    background: #fff !important;
  }
}
@media screen and (max-width: 768px) {
  .bes_logo {
    width: 160px;
  }

  .navbar .container-fluid .navbar-collapse {
    transform: translateY(-67px);
  }
}
</style>