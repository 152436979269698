<template>
  <div class="footerContainer">
    <div class="footerLogo">
      <router-link to="/"
        ><img
          class="bes_logo"
          :src="'../images/assets/BES_white.png'"
          alt="British Lyceum Executive School"
      /></router-link>
    </div>
    <div class="footerAboutUs">
      <h3>Who are we?</h3>
      <p style="text-align: justify">
        British Executive School is premier short course training provider in
        the MENA and EU regions and offers skill focused training courses in
        areas that are critical in augmenting excellence in career of
        executives.
      </p>
    </div>
    <div class="footerContactUs">
      <h3>Contact Us</h3>
      <p><span class="country_name">Call:</span> +44-33-000-10174</p>
      <!-- <p>Whatsapp: +44-79-617-90059</p> -->
      <p>
        <span class="country_name">Email:</span> info@britishexecutiveschool.com
      </p>
      <p><span class="country_name">UK:</span> 875 Romford Road , London</p>
      <p>
        <span class="country_name">Oman:</span> Way No 4864, Azaiba behind
        Sultan Center, Muscat
      </p>
    </div>
  </div>
  <div class="bottom-footer text-center">
    <p class="mb-0">
      © Copyright 2022 British Executive School. All Rights Reserved
    </p>
  </div>
</template>

<script>
export default {
  name: "WebsiteFooter",
};
</script>

<style scoped>
.bes_logo {
  width: 15.625vw;
}

.country_name {
  color: #f8bd3d;
}
.bottom-footer {
  text-align: center;
  background-color: #29577f;
  color: #fff;
  border-top: 1px solid;
  padding: 15px 0px;
}

.footerContainer {
  width: 99.9vw;
  display: flex;
  flex-direction: row;
  background-color: #29577f;
  padding-bottom: 2vw;
  justify-content: space-around;
}

.footerContainer .footerLogo {
  padding-top: 3vw;
  /* padding-left: 3vw; */
}

.footerContainer .footerLogo p {
  color: #fff;
  white-space: nowrap;
  font-size: 1vw;
  margin-top: 0.5vw;
  margin-left: -1rem;
}

.footerContainer .footerAboutUs {
  width: 20vw;
  margin: 3vw 0 0 6vw;
}

.footerContainer .footerAboutUs h3 {
  font-size: 1.6vw;
  color: #fff;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 1.3vw;
}

.footerContainer .footerAboutUs p {
  font-family: "Poppins", sans-serif;
  color: #fff;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1vw;
}

.footerContainer .footerContactUs {
  margin: 3vw 0 0 6vw;
}

.footerContainer .footerContactUs h3 {
  font-size: 1.6vw;
  color: #fff;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 1.3vw;
}

.footerContainer .footerContactUs p {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 1vw;
  margin-bottom: 0.9vw;
}

.footerContainer .footerContactUs p span {
  font-size: 0.9vw;
}

.footerContainer .footerContactUs #address {
  max-width: fit-content;
}

.footerContainer .footerContactUs div a img {
  width: 1.5vw;
  height: 1.5vw;
  margin-right: 1vw;
}

.footerContainer .footerLinks {
  margin: 3vw 0 0 6vw;
}

.footerContainer .footerLinks h3 {
  font-size: 1.6vw;
  color: #fff;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-align: center;
  margin-bottom: 1.3vw;
}

.footerContainer .footerLinks .linksContainer {
  display: flex;
  flex-direction: row;
  gap: 4vw;
}

.footerContainer .footerLinks .linksContainer div {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.footerContainer .footerLinks .linksContainer div a {
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-decoration: none;
  font-size: 1vw;
}

@media screen and (max-width: 991px) {
  .bes_logo {
    margin-top: 2rem;
    width: 154.84px;
    height: 121.44px;
  }

  .footerContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .footerContainer p:first-child {
    font-size: 1.2rem;
    height: fit-content;
    margin-top: 0;
    margin-left: 0;
    width: fit-content;
  }
  .footerContainer .footerLogo {
    margin-left: 2vw;
    padding-left: 3vw;
  }

  .footerContainer .footerLogo p {
    font-size: 0.7rem;
  }

  .footerContainer .footerAboutUs h3,
  .footerContainer .footerContactUs h3,
  .footerContainer .footerLinks h3 {
    font-size: 1.2rem;
    text-align: left;
  }

  .footerContainer .footerContactUs p {
    font-size: 0.8rem;
  }

  .footerContainer .footerContactUs p span {
    font-size: 0.7rem;
  }

  .footerContainer .footerLinks .linksContainer div a {
    font-size: 0.8rem;
  }

  .footerContainer .footerContactUs div a img {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.8rem;
  }

  .footerContainer .footerContactUs,
  .footerContainer .footerLinks {
    padding-left: 0;
    width: fit-content;
    height: fit-content;
  }

  .footerContainer .footerLinks {
    margin-left: 0;
  }

  .footerContainer .footerAboutUs {
    width: fit-content;
    /* margin: 3vw 2vw 0 0; */
    margin: 8vw 2vw 0 0;
  }

  .footerContainer .footerAboutUs p {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 600px) {
  .bes_logo {
    margin-top: -2rem;
    width: 174.84px;
    height: 130.44px;
  }

  .footerContainer .footerContactUs p {
    text-align: center;
  }

  .footerContainer {
    grid-template-columns: 1fr;
  }

  .footerContainer .footerLogo {
    height: fit-content;
    width: fit-content;
    margin: 2rem auto 1rem auto;
  }

  .footerContainer .footerContactUs {
    margin: auto;
  }

  .footerContainer .footerContactUs h3,
  .footerContainer .footerLinks h3 {
    text-align: center;
  }

  .footerContainer .footerContactUs div {
    width: fit-content;
    margin: auto;
  }

  .footerContainer .footerLinks {
    margin: 2rem auto 1rem auto;
  }

  .footerContainer .footerAboutUs {
    display: none;
  }
}
</style>