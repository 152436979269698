import { createApp } from 'vue'
import App from './App.vue'

import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';


import { createPinia } from 'pinia'
const pinia = createPinia()

import routes from './router'

import '@/assets/css/app.css'

createApp(App)
.use(routes)
.use(pinia)
.use(PrimeVue)
.use(ToastService)
.mount('#app')
